import React from 'react'
import { graphql } from 'gatsby'
import EpisodeList from 'components/EpisodeList'

const Podcast = ({ data }) => {
  const episodesPerPage = 10
  return (
    <EpisodeList
      data={data}
      paginationInfo={{
        currentPage: 1,
        numPages: Math.ceil(
          data.allContentfulPodcastEpisode.totalCount / episodesPerPage
        ),
        pageLinkBasepath: '/podcast/',
        nextPageLink: '/podcast/2',
      }}
    />
  )
}

export const pageQuery = graphql`
  query episodeListQuery {
    allContentfulPodcastPage {
      edges {
        node {
          title
          description {
            json
          }
        }
      }
    }
    allContentfulPodcastEpisode(
      sort: { fields: episodeNumber, order: DESC }
      limit: 10
      skip: 0
    ) {
      totalCount
      edges {
        node {
          id
        }
      }
    }
  }
`

export default Podcast
